$(document).ready(() => {
	var swiper = new Swiper(".swiper-reassurance", {
	  slidesPerView: 1,
	  slidesPerGroup:1,
	   breakpoints: {
	    // when window width is >= 540px
	    576: {
	      slidesPerView: 2,
	      slidesPerGroup:2,
	    },
	    // when window width is >= 720px 
	    768: {
	      slidesPerView: 3,
	      slidesPerGroup: 3,
	    },
	    // when window width is >= 992px
	    992: {
	      slidesPerView: 3,
	      slidesPerGroup: 3,
	    },
	    1200: {
	      slidesPerView: 4,
	      slidesPerGroup: 4,
	    }
	  },
	  pagination: {
        el: ".swiper-pagination",
    },
	});
	
	
	var swiper = new Swiper(".swiper-products", {
	   slidesPerView: "auto",
     centeredSlides: true,
	   breakpoints: {
	    // when window width is >= 540px
	    540: {
	      slidesPerView: 2,
	      slidesPerGroup:2,
	    },
	    // when window width is >= 720px 
	    720: {
	      slidesPerView: 2,
	      slidesPerGroup: 2,
	    },
	    // when window width is >= 992px
	    992: {
	      slidesPerView: 4,
	      slidesPerGroup: 4,
	      centeredSlides: false,
	      	  // Navigation arrows
			  navigation: {
			    nextEl: '.sw-nav.next',
			    prevEl: '.sw-nav.prev',
			  },
	    },
	  },
	});
	
		
	
	
		if (!$('.displayHome').hasClass('swiper')) {
			$('.displayHome').addClass('swiper').find('.cb-wrapper').addClass('swiper-slide').
			wrapAll('<div class="swiper-wrapper" />');
			$('.displayHome').append('<div class="sw-nav next"></div><div class="sw-nav prev"></div>');
		} 
	
	var swiperHome = new Swiper(".displayHome", {
	  slidesPerView: 1,
	  slidesPerGroup:1,
	  spaceBetween:30,
	  loop:true,
	  // Navigation arrows
	  navigation: {
	    nextEl: '.sw-nav.next',
	    prevEl: '.sw-nav.prev',
	  },

	});
	
	
});
