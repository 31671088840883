/**
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import swapElements from '@helpers/swapElements';

const {prestashop} = window;

if (prestashop) {
  prestashop.responsive = prestashop.responsive || {};

  prestashop.responsive.current_width = window.innerWidth;
  prestashop.responsive.min_width = 992;
  prestashop.responsive.min_width_portrait = 576;
 console.log(prestashop.responsive.current_width+' < '+prestashop.responsive.min_width);
  prestashop.responsive.mobile = prestashop.responsive.current_width < prestashop.responsive.min_width;
  prestashop.responsive.mobile_portrait = prestashop.responsive.current_width < prestashop.responsive.min_width_portrait;
}

export function toggleMobileStyles() {
  // TODO: Find a better way to manage this with JSDom for tests
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const {prestashop, Theme: {events}} = window;
  if (prestashop.responsive.mobile) {
  	
    Array.prototype.forEach.call(document.querySelectorAll("*[id^='_desktop_']"), (el: HTMLElement): void => {
      const source = document.querySelector(`#${el.id}`);
      const target = document.querySelector(`#${el.id.replace('_desktop_', '_mobile_')}`);

      if (target && source) {
        swapElements(source, target);
      }
    });
  } else {
    Array.prototype.forEach.call(document.querySelectorAll("*[id^='_mobile_']"), (el) => {
      const source = document.querySelector(`#${el.id}`);
      const target = document.querySelector(`#${el.id.replace('_mobile_', '_desktop_')}`);

      if (target && source) {
        swapElements(source, target);
      }
    });
  }

  prestashop.emit(events.responsiveUpdate, {
    mobile: prestashop.responsive.mobile,
  });
}

export default function initResponsiveToggler() {
  // TODO: Find a better way to manage this with JSDom for tests
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const {prestashop} = window;

  prestashop.responsive = prestashop.responsive || {};
  prestashop.responsive.current_width = window.innerWidth;
  prestashop.responsive.min_width = 992;
  prestashop.responsive.min_width_portrait = 576;
  prestashop.responsive.mobile = prestashop.responsive.current_width < prestashop.responsive.min_width;
  prestashop.responsive.mobile_portrait = prestashop.responsive.current_width < prestashop.responsive.min_width_portrait;

  window.addEventListener('resize', () => {
    const currentWidth = prestashop.responsive.current_width;
    const minWidth = prestashop.responsive.min_width;
    const minWidthPortrait = prestashop.responsive.min_width_portrait;
    const screenWidth = window.innerWidth;
    const toggle = (currentWidth >= minWidth && screenWidth < minWidth)
      || (currentWidth < minWidth && screenWidth >= minWidth);
    const togglePortrait = (currentWidth >= minWidthPortrait && screenWidth < minWidthPortrait)
      || (currentWidth < minWidthPortrait && screenWidth >= minWidthPortrait);

    prestashop.responsive.current_width = screenWidth;
    prestashop.responsive.mobile = prestashop.responsive.current_width < prestashop.responsive.min_width;
    prestashop.responsive.mobile_portrait = prestashop.responsive.current_width < prestashop.responsive.min_width_portrait;
    if (toggle || togglePortrait) {
      toggleMobileStyles();
    }
  });
}

document.addEventListener('DOMContentLoaded', () => {
  if (prestashop.responsive.mobile) {
    toggleMobileStyles();
    $("#wrapper .visible--desktop").remove();
		if(prestashop.responsive.mobile_portrait) {
			$("#wrapper .visible--mobile-landscape").remove();
		}else{
			$("#wrapper .visible--mobile-portrait").remove();	
		}
  }else {
		$("#wrapper .visible--mobile").remove();
		$("#wrapper .visible--mobile-portrait").remove();	
	}
});
