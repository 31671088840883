/**
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import themeSelectors from './constants/selectors-map';
import EVENTS from './constants/events-map';
import initEmitter from './prestashop';
import initResponsiveToggler from './responsive-toggler';
import initQuickview from './quickview';
import initCart from './pages/cart';
import initCheckout from './pages/checkout';
import initCustomer from './pages/customer';
import initProductBehavior from './product';
import initMobileMenu from './mobile-menu';
import initSearchbar from './modules/ps_searchbar';
import initLanguageSelector from './modules/ps_languageselector';
import initCurrencySelector from './modules/ps_currencyselector';
import initVisiblePassword from './visible-password';
import initErrorHandler from './errors';
import useToast from './components/useToast';
import useAlert from './components/useAlert';
import usePasswordPolicy from './components/usePasswordPolicy';
import useProgressRing from './components/useProgressRing';
import useQuantityInput from './components/useQuantityInput';
import './modules/blockcart';
import './modules/facetedsearch';
import initDesktopMenu from './modules/ps_mainmenu';
import initFormValidation from './form-validation';
import Swiper from 'swiper';
import  "./components/swiper";
import Headroom from "./components/headroom";


initEmitter();

$(() => {
  const {prestashop, Theme: {events}} = window;

  initProductBehavior();
  initQuickview();
  initCheckout();
  initCustomer();
  initResponsiveToggler();
  initCart();
  useQuantityInput();
  initSearchbar();
  initLanguageSelector();
  initCurrencySelector();
  initMobileMenu();
  initVisiblePassword();
  initDesktopMenu();
  initFormValidation();
  initErrorHandler();
  usePasswordPolicy('.field-password-policy');
  

  prestashop.on(events.responsiveUpdate, () => {
    initSearchbar();
    initLanguageSelector();
    initCurrencySelector();
    initDesktopMenu();
  });

	
	//menu fixe avec la librairie headroom.js
  const header = document.querySelector("body:not(#checkout) .header");
  var options_headroom = {offset : 50};
  const headroom = new Headroom(header,options_headroom);
	headroom.init();
  
  //hover/focus sur header
	$( "#header.header--fixed" ).hover(
	  function() {
	   $(this).removeClass("header--bg-transparent");
	   /* $("#search_widget").css("top", $("#header").height()); */ //pr savoir � quelle hauteur va s'ouvrir la recherche
	  }, function() {
	  	if(!$('#search_widget').hasClass("show")){
		 		$(this).addClass("header--bg-transparent");
			}
	  }
	);
	
	//display searchbar	
	 $('.btn-expand-collapse').click(function(e) {
					$('.search-widgets').toggleClass('collapsed');
					if (!prestashop.responsive.mobile) {
							$('.blocksearch').toggleClass('collapsed');
					}
	});



});

export const components = {
  useToast,
  useAlert,
  useProgressRing,
  useQuantityInput,
};

export const selectors = themeSelectors;

export const events = EVENTS;

export default {
  initProductBehavior,
  initQuickview,
  initCheckout,
  initResponsiveToggler,
  initCart,
  useQuantityInput,
  initSearchbar,
  initLanguageSelector,
  initCurrencySelector,
  initMobileMenu,
  initVisiblePassword,
  initDesktopMenu,
};

        

